import React from "react"

import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = () => (
  <div className="not-found-wrapper">
    <SEO title="404: Not found" />
    <div>
      <StaticImage
        src="../images/spa.jpg"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="404: Page not found"
        placeholder="blurred"
      />
    </div>
  </div>
)

export default NotFoundPage
